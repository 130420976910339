import React from "react"
import "./style.scss"

function Skills() {
  return (
    <>
      <section
        className="education-wrapper"
        id="skills"
        style={{ background: "#EBEEF2" }}
      >
        <div className="empty"></div>

        <div className="wrap">
          <div className="education">
            <p></p>
            <header className="title">
              <h1>Skills</h1>
            </header>
            <main>
              {/* <SkillItem name='Html' width={90} />
              <SkillItem name='css' width={60} />
              <SkillItem name='python' width={35} /> */}
              <ul className="skills-list">
                <li>Security Systems</li>
                <li>Cybersecurity </li>
                <li>Security Analysis</li>
                <li>System Administration </li>
                <li>Information Security </li>
                <li>Network Security </li>
                <li>MS Office</li>
                <li>Data Entry </li>
                <li>Adobe Creative Suite Firewall</li>
                <li>Security Configuration Parameters on Network Devices </li>
                <li>Network Security </li>
                <li>Penetration Testing </li>
                <li>
                  Configuration of Firewalls, Routers, Switches, & Web Security
                  Gateways
                </li>
                <li>Vulnerability Assessments</li>
                <li>
                  Nessus, Rapid7, Metasploit, Nmap, Wireshark, Burp Suite{" "}
                </li>
                <li>
                  Protocols & Services: IPsec, SNMP, SSH, DNS, TLS, SSL, TCPIP.
                  FTPS, HTTPS, SCP, ICMP, IPv4, IPv6, FTP, SFTP, TFTP, Telnet,
                  HTTP, & NetBIOS
                </li>
                <li>Intrusion Detection System </li>
                <li>Intrusion Prevention System (IPS)</li>
                <li>Detection Method</li>
                <li>Configuration Rules for Firewall, VLAN, & Routers </li>
                <li> Access Control Lists </li>
                <li>Data Loss Prevention (DLP Controls)</li>
                <li>Data Sharing/Management Framework (RMF) Package </li>
                <li>Cloud Security</li>
                <li>Data Normalization</li>
                <li>Log Ingestion & Analysis </li>
                <li>Requirements Gathering </li>
                <li>Platforms</li>
              </ul>
            </main>
          </div>
        </div>
      </section>
    </>
  )
}

// const SkillItem = (props) => {
//   return (
//     <div className="skill-item">
//       <h1>{props.name}</h1>
//       <div className="bar">
//         <div className="overlay" style={{ width: props.width + "%" }}></div>
//         {/* <p className="text">{props.width}%</p> */}
//       </div>
//     </div>
//   )
// }

export default Skills
