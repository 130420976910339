import React from "react"
import "./style.scss"
import { FaCloudDownloadAlt } from "react-icons/fa"

function AboutMe() {
  return (
    <section className='about-wrapper' id='about' >
      <div className='empty'></div>
      <div className='max-w'>
        <div className='about-me'>
          <div className='img-holder'>
            <img src={process.env.PUBLIC_URL + '/icon.jpeg'} alt='' />
          </div>
          <aside style={{paddingLeft: '7px'}}>
            <header className='title'>
              <h1>About Me</h1>
            </header>
            <p id='resume'>
            I am a NYC based Cybersecurity analyst/Certified Ethical Hacker with a broad background of expertise in investigative research, analysis, and overall security.

            </p>
            <div className='download-resume'>
              <a href={process.env.PUBLIC_URL + '/DKY@resume.pdf'} download>
              <div className='btn-download'>
                <FaCloudDownloadAlt /> <p >Download Resume</p>
              </div>
              </a>
            </div>
          </aside>
        </div>
        {/* <br /> */}
        {/* <br /> <br /> */}
        {/* <div className='download-resume'>
          <div className='btn-download'>
            <FaCloudDownloadAlt /> <p>Download Resume</p>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default AboutMe
