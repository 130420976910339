import React, { useState } from "react"
import "./Nav.css"

const Nav = () => {
  const [active, setActive] = useState(false)

  const openMenu = () => {
    setActive(!active)
  }

  const closeMenu = () => {
    setActive(false)
  }
  return (
    <>
      <header className='header'>
        <nav className='navbar max-w'>
          <a href='/#' className='nav-logo'>
            Dinesh Yadav
          </a>
          <ul className={`nav-menu ${active && "active"}`}>
            <li className='nav-item' onClick={() => closeMenu()}>
              <a href='/#' className='nav-link'>
                HOME
              </a>
            </li>
            <li className='nav-item'>
              <a href='#about' className='nav-link' onClick={() => closeMenu()}>
                ABOUT
              </a>
            </li>
            <li className='nav-item'>
              <a href='#work' className='nav-link' onClick={() => closeMenu()}>
                WORK
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#skills'
                className='nav-link'
                onClick={() => closeMenu()}>
                SKILLS
              </a>
            </li>
          
            <li className='nav-item'>
              <a
                href='#contact'
                className='nav-link'
                onClick={() => closeMenu()}>
                CONTACT
              </a>
            </li>
          </ul>
          <div
            className={`hamburger ${active && "active"}`}
            onClick={() => openMenu()}>
            <span className='bar'></span>
            <span className='bar'></span>
            <span className='bar'></span>
          </div>
        </nav>
      </header>
      {/* <header style={{ background: "red", height: "60px", width: "100%" }}>
        <nav className='navbar max-w'>
          <a href='#' className='nav-logo'>
            Dinesh.
          </a>
          <ul className={`nav-menu ${active && "active"}`}>
            <li className='nav-item' onClick={() => closeMenu()}>
              <a href='#' className='nav-link'>
                HOME
              </a>
            </li>
            <li className='nav-item'>
              <a href='#about' className='nav-link' onClick={() => closeMenu()}>
                ABOUT
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#skills'
                className='nav-link'
                onClick={() => closeMenu()}>
                SKILLS
              </a>
            </li>
            <li className='nav-item'>
              <a href='#work' className='nav-link' onClick={() => closeMenu()}>
                WORK
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#contact'
                className='nav-link'
                onClick={() => closeMenu()}>
                CONTACT
              </a>
            </li>
          </ul>
          <div
            className={`hamburger ${active && "active"}`}
            onClick={() => openMenu()}>
            <span className='bar'></span>
            <span className='bar'></span>
            <span className='bar'></span>
          </div>
        </nav>
      </header> */}
    </>
  )
}

export default Nav
