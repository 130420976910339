import React from "react";
import "./style.scss";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  return (
    <footer>
      <header>
        <ul>
          <li>
            <FaFacebookF />
          </li>
          <li>
            <FaTwitter />
          </li>
          <li>
            <FaLinkedinIn />
          </li>
        </ul>
      </header>
      <p>© Copyright 2020 Dinesh Yadav</p>
     
    </footer>
  );
}

export default Footer;
