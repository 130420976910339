import React from "react";
import HeroSection from "./components/HeroSection/HeroSection";
import AboutMe from "./components/AboutMe/AboutMe";
import Education from "./components/Education/Education";
import Work from "./components/Work/Work";
import Skills from "./components/Skills/Skills";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import Nav from "./components/Nav/Nav";

function App() {
  return (
    <div className="home-page">
      <Nav />
      <HeroSection />
      <AboutMe />
      <Education />
      <Work />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
