import React from "react";
import "./style.scss";
function Education() {
  return (
    <section className="education-wrapper" id="education">
      <div className="wrap">
        <div className="education">
          <p></p>
          <header className="title">
            <h1>Education</h1>
          </header>
          <main>
            <EducationItem
              name="Fordham University"
              time="Present"
              degree="MSc in Cybersecurity"
              desc="Currently enrolled for M.S. in Cybersecurity"
            />
            <EducationItem
              name="Intellectual Point, Sterling, VA"
              time="August 2021"
              degree="Cyber Security Analyst Pathway | 144 hours"
              desc="Graduated as a Cyber Security Analyst."
            />
            <EducationItem
              name="New York University"
              time="May 2021"
              degree="M.S. in Global Security, Conflict and Cybercrime"
              desc="Graduated as a Cybercrime Policy Analyst."
            />
            <EducationItem
              name="New York University"
              time="Sep 2019("
              degree="BSc. in Social Science"
              desc="Graduated"
              />
          </main>
        </div>
      </div>
    </section>
  );
}

const EducationItem = (props) => {
  return (
    <div className="education-item">
      <h1>{props.name}</h1>
      <h4>
        {props.degree} <span>• {props.time}</span>
      </h4>
      <p>{props.desc}</p>
    </div>
  );
};

export default Education;
