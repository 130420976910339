import React, { useState, useEffect } from "react";
import "./style.scss";
import { FaRegPaperPlane } from "react-icons/fa";

import { useForm, ValidationError } from '@formspree/react';

function Contact() {
 
  const [state, handleSubmit] = useForm("mqkwbzld");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (state.succeeded) {
      setSuccess("Thankyou for reaching out! I will get back to you shortly.");
    }

   
  }, [ state.succeeded]);

  useEffect(() => {
    const timer = setTimeout(() => setSuccess(''),7000);

    return () => clearTimeout(timer)
  }, [success])
  

  return (
    <section id="contact" className="contact-wrapper">
      <div className="wrap">
        <div className="contact">
          <header className="title">
            <h1>Get in touch</h1>
          </header>

          <div className="main">
            <form action=""  onSubmit={handleSubmit}>
              <div className="form-item">
                <label>
                  Full Name <span className="red">*</span>{" "}
                </label>
                <input type="text" required placeholder="Your Name" name='Full Name'/>
              </div>
              <div className="form-item">
                <label>
                  Email <span className="red">*</span>{" "}
                </label>
                <input type="email" required placeholder="Email Address"  name='Email'/>
              </div>
              <ValidationError prefix="Email" field="email" errors={state.errors} />

              <div className="form-item">
                <label>
                  Subject <span className="red">*</span>{" "}
                </label>
                <input type="text" required placeholder="Your Subject"  name='Subject'/>
              </div>

              <div className="form-item">
                <label>
                  Message <span className="red">*</span>{" "}
                </label>
                <textarea required placeholder="Your Messages Here" name='Message'></textarea>
              </div>
              {success && <p className='success'>{success}</p>}
              <div className="download-resume">
                <button type="submit" className="btn-download">
                  <FaRegPaperPlane />
                  <p>Send Message</p>
                </button>
              </div>
            </form>
          </div>

          <div className="info">
            <h1>Dinesh Yadav </h1>
            <p> NYC, NY </p>
            <p> yadavstrong87@gmail.com</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
