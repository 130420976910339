import React from "react"
import "./style.scss"
import SocialButton from "../SocialButton/SocialButton"
import { motion } from 'framer-motion'



function HeroSection() {
  return (
    <motion.div  className='hero-section'>
      <div className='max-w hero-content'>
        <motion.header>
          <motion.h1 initial={{opacity: 0, y: -40}} animate={{opacity: 1, y: 0,  transition: {duration: 0.3, delay: 0.3}}}><span>I'm</span> <span className='blue'>Dinesh Yadav.</span></motion.h1>
          <motion.p initial={{opacity: 0, y: -30}} animate={{opacity: 1, y: 0,  transition: {duration: 0.3, delay: 0.4}}}>I am a NYC based Cybersecurity analyst/Certified Ethical Hacker</motion.p>
          <motion.p initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0,  transition: {duration: 0.3, delay: 0.5}}}>with a broad background of expertise in investigative research, analysis, and overall security.</motion.p>
          <br />
          <SocialButton />
        </motion.header>
      </div>
    </motion.div>
  )
}

export default HeroSection

// { initial={{width: 0, height: 0}} animate={{width: '90%', height: 215,  transition: {duration: 0.4}}}}
