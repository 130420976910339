import React from "react";
function Work() {
  return (
    <section className="education-wrapper" style={{ background: "#fff" }} id="work">
      <div className='empty'></div>
      <div className="wrap">
        <div className="education">
          <p></p>
          <header className="">
            <h1 style={{display: 'inline-block', borderBottom: '4px solid #34A49D', color: '#333'}}>Work</h1>
          </header>
          <main>
            <WorkItem
              name="Mango Consulting LLC, NYC"
              time="Jan 2019 – Present"
              degree="Manager/Policy Analyst	"
              desc="Worked as Manager and Policy Analyst"
            />
            <WorkItem
              name="US Census Bureau/RCC (New York,NY)"
              time="Sep 2019 – Jan 2020"
              degree="Space and Leasing Specialist"
              desc="Worked as a Space and Leasing   Specialist at Regional Office."
            />
            <WorkItem
              name="United States Army(Fort Drum, NY)"
              time="March 2015 – March 2017"
              degree="92A Automated Logistical Specialist"
              desc="92A Automated Logistical Specialist"
            />
          </main>
        </div>
      </div>
    </section>
  );
}

const WorkItem = (props) => {
  return (
    <div className="work-item">
      <h1>{props.name}</h1>
      <h4>
        {props.degree} <span>• {props.time}</span>
      </h4>
      <p>{props.desc}</p>
    </div>
  );
};

export default Work;
