import React from "react";
import "./style.scss";
// import { FaFacebookF } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";


function SocialButton() {
  return (
    <div className="social-button">
      <ul>
        
        <li className="li">
             <a href='https://www.linkedin.com/in/dinesh-yadav-230753120/'><FaLinkedinIn /></a>

        </li>
      </ul>
    </div>
  );
}

export default SocialButton;
